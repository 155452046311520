// @ts-nocheck we are going to come back and check each in-use file
import { storageFactory } from "./utils/storageFactory";
import { stayLoggedIn as newStayLoggedIn } from "design_system/utils/idleTimer";

(function () {
    "use strict";
    window.cedar = window.cedar || {};
    const cedar = window.cedar;

    const KEYCODE_ESCAPE = 27;
    const KEYCODE_TAB = 9;

    cedar.generateElementKeyByString = function generateElementKeyByString(elementText: string) {
        const url = window.location.hostname;
        return md5(`${url}${elementText}`);
    };

    cedar.generateCloseBannerKey = function generateCloseBannerKey(
        $bannerElem: JQuery<HTMLElement>,
    ) {
        const bannerText = $bannerElem.text().trim();
        return cedar.generateElementKeyByString(bannerText);
    };

    cedar.safelyParseJSONFromString = function safelyParseJSONFromString(inputString: string) {
        let results = {};
        try {
            results = JSON.parse(inputString);
        } catch (error) {
            results = {
                error,
            };
        }
        return results;
    };

    /* Helper for toggling aria-expanded attribute */
    cedar.ariaExpandCollapse = function ariaExpandCollapse(elem) {
        $(elem).attr("aria-expanded", $(elem).attr("aria-expanded") === "true" ? "false" : "true");
    };

    const serverDataGlobal = (cedar.serverDataGlobal = cedar.safelyParseJSONFromString(
        $("#_server_data_global").text(),
    ));

    const globalProviderSettings = (cedar.globalProviderSettings = cedar.safelyParseJSONFromString(
        $("#_global_provider_settings").text(),
    ));

    const providerSettings = (cedar.providerSettings = cedar.safelyParseJSONFromString(
        $("#_provider_settings").text(),
    ));

    cedar.providerSettings = { ...globalProviderSettings, ...providerSettings };

    // We can't use fancy backtick for template literals with interpolation here since IE11 doesn't
    // support template literals in js, therefore we do this nasty string concatenation

    Sentry.init({
        dsn:
            "https://" +
            serverDataGlobal.sentry_public_key +
            "@" +
            document.location.host +
            "/client-logging/" +
            serverDataGlobal.sentry_project_id,
        beforeSend(event, hint) {
            // When we utilize Sentry, our errors should always generate an `originalException`.
            const error = hint && hint.originalException;
            const isErrorMissing = !error || error === null;
            // Ignore `UnhandledRejection: Non-Error promise rejection captured with value: null` (CED-9774)
            // Ignore `UnhandledRejection: Non-Error promise rejection captured with value: ` (CED-36552)
            if (isErrorMissing) {
                return null;
            } else {
                // CED-36312: Tracking down `TypeError: undefined is not an object (evaluating 'p.toString')`
                event.extra = event.extra || {};
                // Assign enumerable error properties to extras
                try {
                    event.extra.errorProperties = Object.keys(error).reduce((acc, key) => {
                        acc[key] = error[key];
                        return acc;
                    }, {});
                } catch (error) {
                    console.warn(
                        "[sentry] Failed to assign enumerable error properties to extras",
                        error,
                    );
                }
            }
            return event;
        },
        release: serverDataGlobal.version,
        environment: serverDataGlobal.environment,
        attachStacktrace: true,
        autoSessionTracking: false,
        ignoreErrors: [
            // Ignore [object Object] which don't have a stack trace and are very hard to debug
            // https://github.com/getsentry/raven-js/pull/1181
            /^\[object Object]$/,
            // Ignore any errors related to cookies not being enabled.
            /Cookies are not enabled/,
            // Ignore IE related error regarding sessionStorage not being available (CED-6453)
            /sessionStorage is not available/,
            // Ignore IE related error regarding IndexedDb initialization failure (CED-6627)
            /IndexedDb initialization failed/,
            // Ignore resizeObserver error as it indicates that observations were sent over multiple animation frames
            // instead of just one. See https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
            // (CED-10974)
            /ResizeObserver loop limit exceeded/,
            // Ignore error from known/bad Chrome Extension (CED-9952)
            /ceCurrentVideo\.currentTime/,
            // Ignore error from Mozilla extensions (CED-11173)
            /csRestoreTitle is not defined/,
            // Ignore error from Mobile Safari "Smart Invert Mode" (CED-9950)
            /document\.head\.querySelector\("meta\[name='supported-color-schemes']"\)\.content/,
            //Ignore error from Evernote extension (CED-11094)
            /There is no clipping info for given tab/,
            // Ignore timeout error from bug in third party libraries (CED-9399)
            /Non-Error promise rejection captured with value: Timeout/,
            // Random plugins/extensions
            /vid_mate_check is not defined/,
            /SymBrowser_ModifyAnchorTagWithTarget/,
            /removeNightMode is not defined/,
            // Ignore alerts fromm low-level code (CED-32772, PAT-393)
            /undefined is not an object \(evaluating 'window\.webkit\.messageHandlers/,
            // Ignore alerts only showing in mobile safari for react button clicks (CED-32875)
            /The index is not in the allowed range/,
            // Don't send these errors to sentry as these errors come when a user on Safari or Mobile Safari tries to cancel
            // the page load while fetching, and this error doesn't hinder the UI experience
            // Slack thread with more information
            // https://cedar-slack.slack.com/archives/CRMPWTD42/p1666241268341159?thread_ts=1666197573.316689&cid=CRMPWTD42
            // Stackoverflow post with more information
            // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios/60860369#60860369
            /TypeError: Failed to fetch/,
            /TypeError: NetworkError when attempting to fetch resource./,
            /TypeError: cancelled/,
            /TypeError: Load failed/,
        ],
    });

    Sentry.configureScope(function (scope) {
        scope.setTag("provider_id", serverDataGlobal.provider_id);
        scope.setTag("programming_language", "javascript");
        if (serverDataGlobal.sentry_user_id != null) {
            scope.setUser({ id: serverDataGlobal.sentry_user_id });
        }
    });

    /* Set up storage */
    (function () {
        function InMemoryStore() {
            let storage = {};

            function get(key) {
                return storage[key];
            }

            function set(key, value) {
                storage[key] = value;
            }

            function remove(key) {
                delete storage[key];
            }

            function clear() {
                storage = {};
            }

            return {
                get,
                set,
                remove,
                clear,
            };
        }

        function LocalStorageWrapper() {
            function get(key) {
                return localStorage.getItem(key);
            }

            function set(key, value) {
                localStorage.setItem(key, value);
            }

            function remove(key) {
                localStorage.removeItem(key);
            }

            function clear() {
                localStorage.clear();
            }

            return {
                get,
                set,
                remove,
                clear,
            };
        }

        function supportsLocalStorage() {
            try {
                const testKey = "__TEST_KEY__";
                const testValue = "__TEST_VALUE__";
                localStorage.setItem(testKey, testValue);
                if (localStorage.getItem(testKey) !== testValue) {
                    throw new Error("Invalid localStorage");
                }
                localStorage.removeItem(testKey);
                return true;
            } catch (e) {
                return false;
            }
        }

        cedar.storage = supportsLocalStorage() ? LocalStorageWrapper() : InMemoryStore();
    })();

    /* Navigation handlers */
    cedar.closeSideBar = function (params) {
        const { $originButton = null } = params;
        cedar.toggleSideBar($originButton);
    };

    cedar.toggleSideBar = function ($originButton = null) {
        const $navEl = $(".nav-fixed .nav__sticky");
        const $sideBar = $("#mobile-nav-sidebar");
        $navEl.toggleClass("static");
        $("._nav_sidebar_toggle").attr(
            "aria-expanded",
            $("._nav_sidebar_toggle").attr("aria-expanded") === "true" ? "false" : "true",
        );
        $("._nav_contact_us").toggleClass("hide");
        $(".nav-mobile__overlay").toggleClass("nav-mobile__overlay-extended");
        $(".nav-mobile__overlay").toggleClass("hidden");
        $(".nav-mobile__sidebar").toggleClass("nav-mobile__sidebar-extended");
        $(".nav-mobile__sidebar").toggleClass("hidden");
        $(".nav-mobile__close").toggleClass("nav-mobile__close-extended");
        $("body").toggleClass("overflow-hidden");

        // z-index preventing clicks on the close sidebar "X" from triggering this click event
        $("#navbar-top").toggleClass("z-auto");

        const baseContent = [$(".navbar-content"), $("#main-content"), $("footer")];

        if ($(".nav-mobile__sidebar").hasClass("nav-mobile__sidebar-extended")) {
            // hide all background content
            // set all elements outside the modal to be unfocusable
            $.each(baseContent, function (i, val) {
                val.attr("aria-hidden", "true");
                cedar.setTabIndex(val, "-1");
            });

            // unhide the modal
            $(".navbar-content-mobile").attr("aria-hidden", "false");

            cedar.setModalTrapFocus($sideBar, $originButton, cedar.closeSideBar);
        } else {
            // unhide non-modal content
            // re-enable focus for elements outside the modal
            $.each(baseContent, function (i, val) {
                val.attr("aria-hidden", "false");
                cedar.setTabIndex(val, "0");
            });

            // hide the modal
            $(".navbar-content-mobile").attr("aria-hidden", "true");

            cedar.disableModalTrapFocus($sideBar, $originButton);
        }
    };

    cedar.toggleSideBarV2 = function ($originButton = null, e = null) {
        const $navEl = $(".nav-fixed .nav__sticky");
        const $sideBar = $("#nav-sidebar");
        $navEl.toggleClass("static");
        $("._nav_sidebar_toggle_v2").attr(
            "aria-expanded",
            $("._nav_sidebar_toggle_v2").attr("aria-expanded") === "true" ? "false" : "true",
        );
        $("._nav_contact_us").toggleClass("hide");
        $(".nav__overlay").toggleClass("nav__overlay-extended");
        $(".nav__overlay").toggleClass("hidden");
        $(".nav__sidebar").toggleClass("nav__sidebar-extended");
        $(".nav__sidebar").toggleClass("hidden");
        $(".nav__close").toggleClass("nav__close-extended");
        $("body").toggleClass("overflow-hidden");
        // z-index preventing clicks on the close sidebar "X" from triggering this click event
        $("#navbar-top").toggleClass("z-auto");

        const baseContent = [$(".navbar-content"), $("#main-content"), $("footer")];

        if ($(".nav__sidebar").hasClass("nav__sidebar-extended")) {
            // hide all background content
            // set all elements outside the modal to be unfocusable
            $.each(baseContent, function (i, val) {
                val.attr("aria-hidden", "true");
                cedar.setTabIndex(val, "-1");
            });

            // unhide the modal
            $(".navbar-content").attr("aria-hidden", "false");

            cedar.setModalTrapFocus($sideBar, $originButton, cedar.closeSideBar, e);
        } else {
            // unhide non-modal content
            // re-enable focus for elements outside the modal
            $.each(baseContent, function (i, val) {
                val.attr("aria-hidden", "false");
                cedar.setTabIndex(val, "0");
            });

            // hide the modal
            $(".navbar-content").attr("aria-hidden", "true");

            cedar.disableModalTrapFocus($sideBar, $originButton);
        }
    };

    cedar.getModalFocusableElements = function (elements) {
        const focusableElements =
            "button:visible, a:visible, input:visible, select:visible, textarea:visible, img:visible[aria-expanded='true'], .custom-select:visible";
        if (!Array.isArray(elements)) {
            elements = [elements];
        }

        let focusable = $();
        elements.forEach(function (el) {
            focusable = focusable.add($(el).find(focusableElements));
        });
        return focusable;
    };

    cedar.getModalFocusableTitles = function (elements) {
        const titleElements = "h1[tabindex]:visible, h2[tabindex]:visible, h3[tabindex]:visible";
        if (!Array.isArray(elements)) {
            elements = [elements];
        }

        let focusable = $();
        elements.forEach(function (el) {
            focusable = focusable.add($(el).find(titleElements));
        });
        return focusable;
    };

    cedar.setTabIndex = function ($el, value) {
        const $focusableContent = cedar.getModalFocusableElements($el);
        $focusableContent.attr("tabindex", value);
    };

    cedar.closeModal = function (params) {
        const { $modal } = params;
        $modal.trigger("closeModal");
    };

    cedar.disableModalTrapFocus = function ($modal, $originButton = null) {
        $(document).unbind("keydown");
        cedar.setTabIndex($modal, "-1");
        if ($originButton) $originButton.focus();
    };

    cedar.setModalTrapFocus = function (
        $modal,
        $originButton = null,
        escapeFunction = cedar.closeModal,
        e = null,
    ) {
        cedar.setTabIndex($modal, "0");

        function elementIsVisibleAndFocusable(elem) {
            const $elem = $(elem);
            return !!(
                $elem.css("visibility") === "visible" &&
                $elem.css("display") !== "none" &&
                $elem.is(":visible") &&
                $elem.is(":not(:disabled)")
            );
        }

        function findElement(direction) {
            const $focusableElements = cedar.getModalFocusableElements($modal);
            if ($focusableElements.length === 0) return null;

            let currentIndex = $.inArray(document.activeElement, $focusableElements);
            if (currentIndex === -1)
                currentIndex = direction === "next" ? 0 : $focusableElements.length - 1;

            const increment = direction === "next" ? 1 : -1;
            let nextIndex = currentIndex + increment;

            const loopStart = direction === "next" ? 0 : $focusableElements.length - 1;
            const loopEnd = direction === "next" ? $focusableElements.length : -1;

            let counter = 0;
            while (true) {
                if (nextIndex === loopEnd) nextIndex = loopStart;

                const elem = $focusableElements[nextIndex];
                if (elementIsVisibleAndFocusable(elem)) {
                    return elem;
                }

                if (counter++ >= $focusableElements.length) break;
                nextIndex += increment;
            }

            return null;
        }

        function getNextElement() {
            return findElement("next");
        }

        function getPreviousElement() {
            return findElement("previous");
        }

        function handleKeyDownFocus(e) {
            if (e.which === KEYCODE_ESCAPE) {
                escapeFunction({ $modal, $originButton });
                return;
            }

            if (e.which !== KEYCODE_TAB) return;

            let elem = null;
            if (e.shiftKey) {
                elem = getPreviousElement();
            } else {
                elem = getNextElement();
            }

            if (elem) {
                e.preventDefault();
                $(elem).focus();
            }
        }

        const $focusableContent = cedar.getModalFocusableElements($modal);
        if ($focusableContent.length === 0) return;

        $(document).unbind("keydown");
        $(document).on("keydown", handleKeyDownFocus);

        const $titles = cedar.getModalFocusableTitles($modal);
        let $firstTitle = null;
        if ($titles.length > 0) $firstTitle = $titles.first();

        const firstFocusableElement = $focusableContent.first();
        $firstTitle !== null ? $firstTitle.focus() : $(firstFocusableElement).focus();

        // Prevent automatic focus on provider logo in nav menu v2 when opening sidebar via clicking
        if (e && e.which === 1 && firstFocusableElement.hasClass("navbar-top__logo")) {
            $(firstFocusableElement).blur();
        }
    };
    cedar.handleStartChatFromHelpCenter = function () {
        try {
            const chatCTA = $("._get_help_btn_nav_bar");
            const helpCenterCTA = $("._get_help_center_btn_nav_bar");
            const helpCenterMobileButton = $("#help-center-mobile-button");
            if (chatCTA) {
                chatCTA.removeClass("hidden");
            }
            if (helpCenterCTA) {
                helpCenterCTA.addClass("invisible s768:visible");
            }
            if (helpCenterMobileButton) {
                helpCenterMobileButton.removeClass("hidden");
            }
        } catch (e) {}
    };

    (function () {
        // cached jQuery selectors
        const $closeBannerElem = $("._close_banner_above_nav");
        const $bannerElem = $("._banner_above_nav");
        const sessionStore = storageFactory(() => sessionStorage);

        // Mobile nav sidebar
        $("._nav_sidebar_toggle").click(function () {
            cedar.toggleSideBar($(this));
        });
        $("#nav-mobile-close").click(function () {
            cedar.toggleSideBar();
            $("._nav_sidebar_toggle").focus();
        });

        $("#mobile-nav-wrapper-first").focus(function () {
            // cycle back to the last focusable element in the modal
            // get all focusable inside the modal
            const $focusableContent = cedar.getFocusableChildrenElements($("#mobile-nav-sidebar"));

            // move focus to the second to last element
            // skipping the last because of the dummy mobile-nav-wrapper-last element
            $focusableContent.eq(-2).focus();
        });

        $("#mobile-nav-wrapper-last").focus(function () {
            // cycle back to the first focusable element in the modal
            // get all focusable inside the modal
            const $focusableContent = cedar.getFocusableChildrenElements($("#mobile-nav-sidebar"));

            // move focus to the second element
            // skipping the first because of the dummy mobile-nav-wrapper-first element
            $focusableContent.eq(1).focus();
        });

        // Nav sidebar v2, gated behind navigation_menu_v2_enabled model setting (desktop and mobile)
        $("._nav_sidebar_toggle_v2").click(function (e) {
            cedar.toggleSideBarV2($(this), e);
        });

        // Set up a click handler for the enter key to distinguish when patients enter the nav menu
        // via mouse click vs. tabbing + enter key
        $("._nav_sidebar_toggle_v2").keydown(function (e) {
            if (e.which === 13) {
                e.preventDefault();
                cedar.toggleSideBarV2($(this), e);
            }
        });

        $("#nav-wrapper-first").focus(function () {
            // cycle back to the last focusable element in the modal
            // get all focusable inside the modal
            const $focusableContent = cedar.getFocusableChildrenElements($("#nav-sidebar"));

            // move focus to the second to last element
            // skipping the last because of the dummy nav-wrapper-last element
            $focusableContent.eq(-2).focus();
        });

        $("#nav-wrapper-last").focus(function () {
            // cycle back to the first focusable element in the modal
            // get all focusable inside the modal
            const $focusableContent = cedar.getFocusableChildrenElements($("#nav-sidebar"));

            // move focus to the second element
            // skipping the first because of the dummy nav-wrapper-first element
            $focusableContent.eq(1).focus();
        });

        // set up close-banner-click watcher
        $closeBannerElem.click(function () {
            // hide banner
            $bannerElem.css({ display: "none" });
            // record close banner in session
            const closeBannerKey = cedar.generateCloseBannerKey($bannerElem);
            sessionStore.setItem(closeBannerKey, true);
        });

        // show the banner if session doesn't say it should be closed
        if ($closeBannerElem) {
            const closeBannerKey = cedar.generateCloseBannerKey($bannerElem);
            if (sessionStore.getItem(closeBannerKey) !== "true") {
                $bannerElem.css({ display: "block" });
            }
        }

        const ONE_HOUR_UNIX = 60 * 60 * 1000;
        const currentUnixTimestamp = Date.now();
        if (localStorage.getItem("lastChatTimestamp") != null) {
            const lastChatTimestampValue = Number(localStorage.getItem("lastChatTimestamp"));

            const within24Hours =
                lastChatTimestampValue > currentUnixTimestamp - 24 * ONE_HOUR_UNIX;
            const within1Hour = lastChatTimestampValue > currentUnixTimestamp - ONE_HOUR_UNIX;

            const loggedInCheck = serverDataGlobal.logged_in && within24Hours;
            const notLoggedInCheck = !serverDataGlobal.logged_in && within1Hour;

            if (loggedInCheck || notLoggedInCheck) {
                cedar.handleStartChatFromHelpCenter();
            }
        }
    })();

    cedar.regex = {
        /**
         * Example usage:
         *
         * isValidText.test.bind(isValidText)(string)
         *
         * Make sure to bind the regex to itself before you call use it
         * Otherwise it will call RegExp.prototype.test
         */
        isValidText: new RegExp(/^([a-zA-Z0-9 _-]+)$/),
        isValidAddress: new RegExp(/^([a-zA-Z0-9.# _-]+)$/),
        isAlphabet: new RegExp(/[a-zA-Z]/),
        isNumberOrDash: new RegExp(/[\d-]/),
        isNumber: new RegExp(/[\d]/),
        /**
         * Text doesn't start or end with a dash or have consecutive dashes.
         * Only allow alphanumerical characters and dashes.
         */
        isValidId: new RegExp(/^([\da-zA-Z_]+-?)+[\da-zA-Z_]+$/),
        /**
         * Text doesn't start or end with a dash or have consecutive dashes.
         * Number only.
         */
        isValidUSZip: new RegExp(/^\d{5}(?:-\d{4})?$/),
    };

    /**
     * Shortcut to bind all methods on a class
     * @param obj
     */
    cedar.bindAllMethods = function (obj) {
        const properties = Object.getOwnPropertyNames(obj).concat(
            Object.getOwnPropertyNames(Object.getPrototypeOf(obj)),
        );

        properties.forEach((property) => {
            if (typeof obj[property] === "function") {
                obj[property] = obj[property].bind(obj);
            }
        });
    };

    /* Helper for parsing query-strings. */
    cedar.parseQuery = function (queryString) {
        if (queryString.indexOf("?") === 0) {
            queryString = queryString.substr(1);
        }
        return queryString.split("&").reduce(function (paramDict, paramString) {
            const keyValArray = paramString.split("=");
            paramDict[keyValArray[0]] = keyValArray[1] || true;
            return paramDict;
        }, {});
    };

    /* Helper for recording patient app click events */
    cedar.recordEvent = function (data) {
        const eventUrl = "/events/record";
        const pageParams = cedar.parseQuery(window.location.search);
        const time = { time: new Date().getTime() };
        const currentPage = { current_page: window.location.pathname };
        data = Object.assign(data, pageParams, currentPage, time);
        $.get(eventUrl + (data ? "?" + $.param(data) : ""));
    };

    /* Handle login and logout across pages */
    if (serverDataGlobal.auto_refresh_logout_enabled) {
        newStayLoggedIn(
            serverDataGlobal.csrf_token,
            serverDataGlobal.logged_in,
            serverDataGlobal.logged_in ? 60000 : 240000,
        );
    } else {
        (function () {
            const loggedInStorage = cedar.storage.get("loggedIn") === "true";
            if (loggedInStorage !== serverDataGlobal.logged_in) {
                cedar.storage.set("loggedIn", serverDataGlobal.logged_in);
            }

            function stayLoggedIn() {
                $.post("/stay-logged-in/", {
                    csrfmiddlewaretoken: serverDataGlobal.csrf_token,
                }).then(function (response) {
                    if (serverDataGlobal.logged_in !== response.logged_in) {
                        document.location.reload();
                    }
                });
            }
            const throttleStayLoggedIn = _.throttle(
                stayLoggedIn,
                serverDataGlobal.logged_in ? 60000 : 240000,
                {
                    leading: true,
                    trailing: false,
                },
            );

            // Delay adding listeners so we don't make unecessary calls at the beginning of window
            setTimeout(function delayedListen() {
                ["mousemove", "click", "keydown", "scroll"].forEach(function (event) {
                    document.addEventListener(event, throttleStayLoggedIn, {
                        passive: true,
                    });
                });
            }, 30000);

            window.addEventListener("storage", function (event) {
                if (event.key === "loggedIn" && serverDataGlobal.reload_on_auth_change) {
                    window.location.replace(window.location.href);
                }
            });
        })();
    }

    /* Popup handler */
    $("body").on("click", ".pop-up-toggle", function (e) {
        e.preventDefault();
        $(this).children(".pop-up-text").toggleClass("show");
    });
})();

// --- General helper functions begin ---

// eslint-disable-next-line no-unused-vars
cedar.formatMoney = function formatMoney(num: number) {
    // If num is a whole number, change precision to no decimals
    let precision = 2;
    if (num % 1 == 0) {
        precision = 0;
    }

    // NOTE this isn't localized to anything other than US
    return accounting.formatMoney(num, "$", precision);
};

// eslint-disable-next-line no-unused-vars
cedar.formatMoneyFixed = function formatMoneyFixed(num: number) {
    // NOTE this isn't localized to anything other than US
    return accounting.formatMoney(num, "$", 2);
};

// eslint-disable-next-line no-unused-vars
cedar.processDOBField = function processDOBField(dobFieldObject, selectorString, outputField) {
    // please keep month/day/year field names the same, otherwise change this
    dobFieldObject.val(dobFieldObject.val().trim());

    if (parseInt(dobFieldObject.attr("maxlength")) === dobFieldObject.val().length) {
        dobFieldObject.next(selectorString).focus().select();
    }

    const parentBucket = dobFieldObject.parent();

    const monthVal = parentBucket.find('input[name="dob_month"]').val();
    const dayVal = parentBucket.find('input[name="dob_day"]').val();
    const yearVal = parentBucket.find('input[name="dob_year"]').val();

    outputField.val(monthVal + " / " + dayVal + " / " + yearVal);
};

// eslint-disable-next-line no-unused-vars
cedar.clearDOBField = function clearDOBField(e, dobFieldObject, selectorString) {
    const BACKSPACE_KEYCODE = 8;
    if (e.keyCode === BACKSPACE_KEYCODE && dobFieldObject.val().length === 0) {
        dobFieldObject.prev(selectorString).focus();
    }
};

cedar.emailIsInvalid = function emailIsInvalid(value) {
    const atindex = value.lastIndexOf("@");
    const dotindex = value.lastIndexOf(".");
    if (!(atindex > 0 && dotindex > atindex)) {
        return gettext("Email should be like user@example.com");
    }
    return false;
};

// --- General helper functions end   ---

// Suppress :focus style when button is clicked
$("button").on("mousedown", (e) => e.preventDefault());
$("a").on("mousedown", (e) => e.preventDefault());

// Add functionality to close modal
$("._modal_close").click(function () {
    $("._modal").trigger("closeModal");
});

// Add tabindex to main-content when user clicks on skip link
$("#skip-link,#skip-link-invisible").on("click", (e) => {
    $("#main-content").attr("tabindex", "-1");
});

// Remove tabindex after main-content loses foucs
$("#main-content").on("blur", (e) => {
    $("#main-content").removeAttr("tabindex");
});

$("._see_more_btn").on("click", () => {
    $("._see_more_btn").toggleClass("hidden");
    $("._see_more_details_container").slideToggle();
});

// Since bills link routes to home page, close the sidebar
// if the user clicks on the home page link while already on the home page
$('[data-test="nav-sidebar-bills"]').on("click", () => {
    if (window.location.href.includes("/home/")) {
        cedar.toggleSideBarV2($(this));
    }
});
